.dialog {
  background-color: var(--background-primary-color);
  border-radius: 1rem;
  border: none;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  pointer-events: auto;

  :global(.modal-body) {
    overflow: visible;
  }

  :global(.modal-header) {
    padding: 0.5rem 1rem;

    :global(.modal-title) {
      font-size: 1rem;
      font-weight: 700;
    }
  }

  :global(.modal-footer) {
    border-top: none;
  }
}

.positionedDialog {
  position: absolute;
  height: auto;
  overflow-x: hidden;
  max-width: 100%;
}