.text-knowingo-blue {
  color: var(--primary-color);
}

table {
  th,
  td {
    color: var(--background-text-color);
  }

  th {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table {
    tr:last-of-type {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.btn-link {
  text-decoration: none;
  color: var(--primary-color);
}

// Fix breadcrumbs in RTL layout
html[dir="rtl"] {
  .breadcrumb-item {
    padding-left: 0;
    padding-right: 0.5rem;

    &::before {
      content: none;
    }
  }

  .breadcrumb-item:not(:last-of-type) {
    &::after {
      float: left;
      padding-right: 0.5rem;
      color: var(--primary-color);
      content: "/";
    }
  }
}

// Fix for carousel items in RTL items
html[dir="rtl"] {
  .carousel-item {
    margin-right: 0;
    margin-left: -100%;
  }
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-background {
  color: var(--background-text-color) !important;
}

.dropdown-menu {
  border-radius: 1rem;
  background-color: var(--background-primary-color);

  .dropdown-item {
    color: var(--background-text-color);

    &:hover {
      background-color: var(--highlight-color);
    }
  }
}


.nav {
  &.nav-pills-primary {
    gap: 1rem;

    .nav-item {
      flex-grow: 1;
      flex-basis: 0;
      text-align: center;

      > a.nav-link {
        border-radius: .85rem;
        background-color: var(--highlight-color);
        color: var(--background-text-color);

        &.active {
          background-color: var(--primary-color);
          color: white;
        }
      }
    }
  }
}