.confirmation-container {
  .confirmation-backdrop {
    z-index: 1050;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: #000;
    opacity: 0.5;
  }

  .confirmation-item {
    z-index: 1051;
    opacity: 1;
    position: fixed;
    background-color: var(--background-primary-color);
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 1rem;

    .confirmation-heading {
      font-family: Exo;
      position: relative;
      margin: 0.45em 0;
      font-weight: 700;
      text-align: center;

      .close-btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .confirmation-body {
      padding: 1em 2em;
    }

    .confirmation-footer {
      padding: 1em;
      display: flex;
      justify-content: flex-end;
    }
  }
}
