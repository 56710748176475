a {
  text-decoration: none;
  color: var(--primary-color);
}

section.page {
  section.page-body {
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 1.5em;

    background-color: var(--background-primary-color);
    border-radius: 1rem;
  }
}

// Headers
h5 {
  font-size: 1.1em;
  font-weight: 600;
}

// Tables
.table {
  tr.disabled {
    td {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.table-accent-primary {
  background-color: var(--background-primary-color);

  thead {
    tr {
      th {
        border-bottom: none;
        color: #fff;
        background-color: var(--primary-color);

        &:first-child {
          border-top-left-radius: 1rem;
        }

        &:last-child {
          border-top-right-radius: 1rem;
        }
      }
    }
  }

  tbody {
    background-color: var(--background-secondary-color);

    tr {
      td {
        background-color: var(--background-primary-color);
        color: var(--background-text-color);

        border-bottom-color: var(--background-secondary-color);
        border-bottom-width: 1px;
      }

      &.expanded,
      &.selected,
      &:hover {
        td {
          background-color: var(--highlight-color);
        }
      }

      &:last-of-type {
        td {
          &:first-of-type {
            border-bottom-left-radius: 1rem;
          }
          &:last-of-type {
            border-bottom-right-radius: 1rem;
          }
        }
      }

      &.nested {
        td {
          background-color: var(--highlight-secondary-color);
        }
      }
    }
  }
}

.table-accent-light {
  background-color: transparent;

  // Override bootstrap defaults
  tr > td,
  tr > th {
    background-color: transparent !important;
    color: var(--background-text-color);
  }

  thead {
    tr > th {
      border-bottom: none;
      font-weight: 500;
    }
  }

  tbody {
    tr {
      position: relative;

      > td:first-child::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid var(--highlight-color);
        border-top: none;
        pointer-events: none;
      }

      &:first-of-type > td:first-child::after {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-top: 1px solid var(--highlight-color);
      }

      &:last-of-type > td:first-child::after {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }

      &.has-error {
        &:after {
          border-color: var(--red-color);
          border-top: 1px solid var(--red-color);
        }
      }

      td {
        border: none;
        vertical-align: middle;
        padding: 0.5rem 1rem;
      }
    }
  }
}

html[dir="rtl"] {
  .table-accent-primary {
    thead {
      th {
        &:first-child {
          border-top-left-radius: 0;
          border-top-right-radius: 1rem;
        }

        &:last-child {
          border-top-left-radius: 1rem;
          border-top-right-radius: 0;
        }
      }
    }
  }
}

ul.pagination.pagination-custom {
  li {
    background-color: var(--background-primary-color);
    &:first-child {
      border-radius: 1rem 0 0 1rem;
    }

    &:last-child {
      border-radius: 0 1rem 1rem 0;
    }

    .page-link {
      border: none;
      background-color: transparent;
      color: var(--background-text-color);
    }
  }
  li.page-item.active {
    button {
      color: #fff;
      border-radius: 0.75rem;
      background-color: var(--primary-color);
    }
  }
}

// Labels
label {
  font-size: 0.8rem;
}

// Inputs
.input-group-custom {
  &.input-group-accent-light {
    .input-group-text {
      border: none;
      background-color: transparent;
      color: var(--primary-color);
    }
  }
}

.form-control.accent-light {
  border: 1px solid var(--gray-300-color);
  box-shadow: 0 3px 2px -2px var(--gray-300-color);

  border-radius: 0;

  &:focus,
  &:hover,
  &.active {
    box-shadow: 0 3px 2px -2px var(--primary-color);
  }

  &.is-invalid {
    box-shadow: 0 3px 2px -2px var(--red-color);
  }
}

.sup-plus {
  color: var(--primary-color);
}

.highlight {
  animation: highlight 1000ms ease-out;
}

@keyframes highlight {
  from {
    background-color: var(--primary-25-color);
  }
}

.list-group.list-group-custom {
  .list-group-item {
    border-radius: 0;
    background-color: var(--background-primary-color);
    color: var(--background-text-color);

    &.inactive {
      opacity: 0.75;
      cursor: not-allowed;
    }

    .row {
      align-items: center;
    }
  }
}

.loading {
  position: relative;

  > * {
    opacity: 0.25;
  }

  &:before {
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    top: calc(50% - 18px);
    left: calc(50% - 18px);
    color: var(--primary-color);
    background-color: transparent;
    content: "\f110";
    font-size: 3rem;
    animation: fa-spin 2s linear infinite;
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    color: var(--background-text-color);
    background-color: transparent;
    content: attr(data-loading-text);
    font-size: 1.25rem;
    margin-top: 36px;
  }
}

i.bg-circle {
  padding: 0.3em;
  width: 1.75em;
  height: 1.75em;
  line-height: 1.25em;
  border-radius: 50%;
  text-align: center;

  &.bg-circle-gray {
    color: white;
    background-color: var(--gray-600-color);
  }

  &.bg-circle-blue {
    color: white;
    background-color: var(--primary-color);
  }

  &.bg-circle-orange {
    color: white;
    background-color: var(--yellow-color);
  }

  &.bg-circle-red {
    color: white;
    background-color: var(--red-color);
  }
}

.image-container {
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  font-size: 2em;

  img {
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
  }

  &:has(img[src=""]) {
    &:before {
      position: absolute;
      content: "\f302";
      font-family: "Font Awesome 5 Pro";
      color: var(--gray-300-color);
      opacity: .65;

      left: 50%;
      top: 50%;
      z-index: 0;
      transform: translate(-50%, -50%);
    }
  }
}

a.nav-custom {
  color: inherit;
  &:hover {
    color: var(--primary-color);
  }
}

img.image {
  max-width: 100%;
  max-height: 100%;
}

.dropdown-item.disabled {
  opacity: 0.35;
  cursor: not-allowed;
}

.breadcrumb {
  background-color: transparent;
}

.nav.custom-nav {
  background-color: var(--background-primary-color);
  border-radius: 1rem 1rem 0 0;
  margin-bottom: -0.75rem;
  padding-bottom: 1rem;

  .nav-link {
    padding: 0.75rem 1rem;

    color: var(--background-text-color);
    opacity: 0.75;

    &.active,
    &:hover {
      opacity: 1;
      color: var(--background-text-color);
      box-shadow: 0 3px 2px -2px var(--primary-color);
    }
  }
}

.shadow-custom {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2) !important;
}

.overlay-dialog {
  z-index: 1;
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.grayed-out {
  filter: grayscale(1) opacity(0.5);
}

.tabs-light {
  &.nav.nav-tabs {
    border: none;

    .nav-item > .nav-link {
      border: none;
      background-color: transparent;
      color: var(--background-muted-text-color);

      &.active,
      &:hover {
        color: var(--primary-color);
        box-shadow: 0 3px 2px -2px var(--primary-color);
      }
    }
  }
}

ul {
  li {
    padding: .25rem 0;

    i {
      color: var(--primary-color);
    }
  }
}

pre {
  background-color: var(--background-secondary-color);
  padding: 1rem;
  border-radius: 1rem;
}

.card.card-transparent {
  width: 350px;
  border-radius: 1rem;

  background-color: var(--background-primary-color);
  color: var(--background-text-color);

  .card-header {
    padding-top: 1rem;
    background-color: transparent;
    border-bottom: none;
  }

  .card-footer {
    background-color: transparent;
    border-top: none;
    padding-bottom: 1rem;
  }

  .imageWrapper {
    overflow: hidden;
    height: 120px;
    border-radius: 1rem;
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      font-size: 3rem;
      content: "\f03e";
      z-index: 0;
      opacity: .5;
      color: var(--gray-200-color);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s;
      z-index: 1;
      transform: scale(1.01);
    }
  }

  &:hover {
    .imageWrapper {
      img {
        transform: scale(1.1);
      }
    }
  }

  :global(.card-body) {
    padding-bottom: 0;
  }

  :global(.card-footer) {
    background-color: transparent;
    border: none;
  }
}