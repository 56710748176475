.dropdownToggle {
  text-align: start;
  padding-right: 1rem;

  display: flex;
  align-items: center;

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:global(.dropdown-toggle) {
    &:after {
      border: none;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      content: "\f0d7";
      margin-left: auto;
      vertical-align: unset;
      padding: 0 .25rem 0 .75rem;
    }

    span {
      display: inline-block;
    }
  }
}
