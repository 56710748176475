.faded {
  opacity: 0.5;
}

.text-orange {
  color: var(--yellow-color);
}

.text-green {
  color: var(--green-color);
}

.text-gray {
  color: var(--gray-600-color);
}

.text-blue {
  color: var(--primary-color);
}

.text-red {
  color: var(--red-color);
}

.text-yellow {
  color: var(--yellow-color);
}

.no-border {
  border: none !important;
}

[data-subtitle] {
  &::after {
    content: attr(data-subtitle);
    display: block;
    font-size: 0.8rem;
    margin-top: -0.5rem;
    margin-left: 0.1rem;
    text-align: left;
    text-transform: uppercase;
  }

  &.subtitle-orange {
    &::after {
      color: var(--yellow-color);
    }
  }
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.overflow-visible {
  overflow: visible !important;
}

.disabled {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: none;
}