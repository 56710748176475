@font-face {
  font-family: "Exo";
  src: url("/fonts/exo/Exo-Thin.ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Exo";
  src: url("/fonts/exo/Exo-ExtraLight.ttf");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Exo";
  src: url("/fonts/exo/Exo-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Exo";
  src: url("/fonts/exo/Exo-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Exo";
  src: url("/fonts/exo/Exo-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Exo";
  src: url("/fonts/exo/Exo-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Exo";
  src: url("/fonts/exo/Exo-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Exo";
  src: url("/fonts/exo/Exo-ExtraBold.ttf");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Exo";
  src: url("/fonts/exo/Exo-Black.ttf");
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Thin.ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-ExtraLight.ttf");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-ExtraBold.ttf");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Black.ttf");
  font-weight: 900;
  font-display: swap;
}

:root {
  //--primary-100-color: #dbf8ff;
  //--primary-200-color: #b7f1ff;
  //--primary-300-color: #92eaff;
  //--primary-400-color: #6ee4ff;
  //--primary-500-color: #4addff;
  //--primary-600-color: #26d6ff;
  //--primary-700-color: #02cfff;
  --primary-800-color: #00b3dc;
  //--primary-900-color: #0095b8;
  --primary-color: #007894;

  --yellow-color: #fab732;
  --orange-color: #ff751a;
  --red-color: #f0593e;
  --green-color: #60bc56;
  --black-color: #363636;

  --gray-100-color: #f6f6f6;
  --gray-200-color: #ededed;
  --gray-300-color: #d3d3d3;
  --gray-500-color: #a9a9a9;
  --gray-600-color: #555555;
  --gray-700-color: #434343;
  --gray-800-color: #363636;
  --gray-900-color: #282828;
}

@mixin darkThemeColors {
  --background-text-color: white;
  --background-muted-text-color: var(--gray-500-color);
  --background-primary-color: var(--black-color);
  --background-secondary-color: var(--gray-900-color);
  --background-dark-color: var(--gray-600-color);
  --highlight-color: var(--gray-600-color);
  --highlight-secondary-color: var(--gray-700-color);
  --primary-color: #0093b6;

  input {
    color-scheme: dark;
  }
}

@mixin lightThemeColors {
  --background-text-color: var(--black-color);
  --background-muted-text-color: var(--gray-500-color);
  --background-primary-color: white;
  --background-secondary-color: var(--gray-200-color);
  --background-dark-color: var(--gray-300-color);
  --highlight-color: var(--gray-200-color);
  --highlight-secondary-color: var(--gray-100-color);
  --primary-color: #007894;
}

@media (prefers-color-scheme: light) {
  :root {
    @include lightThemeColors;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    @include darkThemeColors;
  }
}

html,
body {
  height: 100%;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  padding: 0 !important;

  color: var(--background-text-color);
}

html {
  position: relative;
}

body {
  background-color: var(--background-secondary-color);
  transition: background-color 0.3s ease-in-out;

  &.theme-dark {
    @include darkThemeColors;
  }

  &.theme-light {
    @include lightThemeColors;
  }
}

#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Exo, serif;
}

a,
.btn-link {
  color: #3c8dbc;
  &:hover,
  &:active,
  &:focus {
    outline: none;
    text-decoration: none;
    color: #72afd2;
  }
}

.text-muted {
  color: var(--background-muted-text-color) !important;
}

.text-default {
  color: var(--background-text-color) !important;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rounded {
  border-radius: 1rem !important;
}

.font-exo {
  font-family: "Exo", serif;
}

small {
  font-weight: 400;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-highlight {
  background-color: var(--highlight-secondary-color);
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-primary-background {
  color: var(--background-text-color);
  background-color: var(--background-primary-color) !important;
}

.font-lg {
  font-size: 1.75rem;
}

.font-sm {
  font-size: .9rem;
  font-weight: 400;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}