#loading {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.33);

  &:before {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

#loading {
  display: none;

  position: fixed;
  z-index: 1080; // Max z-index in Bootstrap is 1070 for tooltips

  text-align: center;

  &.active {
    display: block;

    font-size: 2em;
    line-height: 4em;
    height: 4em;
    width: 4em;

    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    border-radius: 0.5em;

    &:before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .spin {
    animation: spin 750ms infinite linear;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
}
