fieldset:disabled {
  :global(.rw-widget-input) {
    opacity: 0.5;
    background-color: var(--highlight-color);
  }
}

.dropdownList {
  position: relative;
  display: flex;
  align-items: center;

  > i {
    color: var(--primary-color);
  }

  :global(.rw-popup-container) {
    max-width: 100%;
    min-width: 100%;
    right: unset;
  }

  :global(.rw-state-disabled) {
    opacity: 0.25;

    :global(.rw-widget-input) {
      opacity: 0.5;
      background-color: var(--highlight-color);
    }
  }

  :global(.rw-state-focus .rw-widget-picker.rw-widget-input) {
    box-shadow: inset 0 0 2px 0 var(--primary-color);
  }

  :global(.rw-widget-input) {
    background-color: transparent;
    border: none;

    transition: box-shadow 0.15s ease-in-out;
    border-radius: 0.75rem;
    box-shadow: inset 0 0 2px 0 var(--gray-500-color);

    :global(.rw-dropdown-list-value),
    :global(.rw-picker-caret),
    :global(.rw-dropdownlist-search),
    :global(.rw-placeholder) {
      color: var(--background-text-color);
    }



    :global(.rw-placeholder) {
      opacity: .5;
    }
  }

  :global(.rw-list),
  :global(.rw-popup) {
    border-radius: 1rem;
    border: none;
    background-color: var(--background-primary-color);
  }

  :global(.rw-list) {
    :global(.rw-list-option) {
      border: none;
      position: relative;
      white-space: nowrap;
      color: var(--background-text-color);

      overflow: hidden;
      text-overflow: ellipsis;

      &:global(.rw-state-selected) {
        background-color: transparent;

        &:before {
          content: "\f00c";
          font-family: "Font Awesome 5 Pro";
          font-size: 0.9rem;
          color: var(--primary-color);
          position: absolute;
          left: 0.5rem;
        }
      }

      &:hover {
        background-color: var(--highlight-color);
      }
    }
  }
}

.hasError {
  color: var(--red-color);

  i {
    color: var(--red-color);
  }

  :global(.rw-widget-input) {
    box-shadow: inset 0 0 2px 0 var(--red-color);

    :global(.rw-dropdown-list-value),
    :global(.rw-picker-caret),
    :global(.rw-placeholder) {
      color: var(--red-color);
    }
  }
}

.clearButton {
  position: absolute;
  top: 0;
  height: 100%;
  right: 1rem;
}

html[dir="rtl"] {
  .clearButton {
    right: auto;
    left: 1.25rem;
  }
}
