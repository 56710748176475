@use "style/variables";

table.k-table {
  width: 100%;
  border-collapse: collapse;

  th {
    font-weight: bold;
    vertical-align: middle;
  }

  th.sortable {
    cursor: pointer;
  }

  td,
  th {
    padding: 6px;

    white-space: nowrap;
  }

  td {
    vertical-align: middle;

    &.col-gray {
      background-color: var(--gray-600-color);
    }
    &.col-blue {
      background-color: var(--primary-color);
    }
    &.col-orange {
      background-color: var(--yellow-color);
    }
    &.col-red {
      background-color: var(--red-color);
    }

    .col-dynamic-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 200px;

      @media screen and (min-width: variables.$bootstrap-grid-sm) {
        max-width: 400px;
      }
      @media screen and (min-width: variables.$bootstrap-grid-md) {
        max-width: 500px;
      }
      @media screen and (min-width: variables.$bootstrap-grid-lg) {
        max-width: 600px;
      }
    }

    .mobile-header {
      display: none;
    }

    .data {
      width: 100%;
      float: none;
    }
  }
}

html[dir="rtl"] {
  table.k-table {
    @media only screen and (max-width: 760px),
      (min-device-width: 768px) and (max-device-width: 1024px) {
      td {
        .mobile-header {
          float: right;
        }
        .data {
          float: left;
        }
      }
    }
  }
}

.table-controls {
  width: 100%;
  padding: 5px;

  background-color: var(--background-primary-color);
  color: var(--background-text-color);

  border-radius: 1rem 1rem 0 0;

  .btn {
    text-transform: none;
  }
}

.table-fixed-header {
  overflow-y: auto;
  overflow-x: auto;

  thead th {
    position: sticky;
    top: 0;
  }
}

.col-thumbnail {
  img {
    z-index: 0;
    height: 50px;
  }
}

.col-selected {
  width: 2rem;
}

table {
  td {
    &.has-error {
      position: relative;
      box-shadow: 0 0 2px 1px var(--red-color);

      .cell-error {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.table-spaced {
  table {
    margin-bottom: 0; // Overwrite default's Bootstrap margin

    tr {
      th,
      td {
        padding: 0.65rem 1rem;
      }

      th:first-child,
      td:first-child {
        padding-left: 1rem;
      }

      th:last-child,
      td:last-child {
        padding-right: 1rem;
      }
    }
  }
}

.caret-icon {
  margin: 0.25rem;
  display: inline-block;
  visibility: hidden;
}

.expandable {
  .caret-icon {
    visibility: visible;
  }
}

.nested-1 .caret-icon {
  margin-left: 1.2rem;
}

.nested-2 .caret-icon {
  margin-left: 1.6rem;
}

.nested-3 .caret-icon {
  margin-left: 2rem;
}

.nested-4 .caret-icon {
  margin-left: 2.4rem;
}

.nested-5 .caret-icon {
  margin-left: 2.8rem;
}
