.carousel.carousel-custom {
  width: 100%;
  height: 100%;

  .carousel-control-prev,
  .carousel-control-next {
    width: 1.5rem;
  }

  .carousel-indicators {
    font-size: 0.8rem;
    background-color: transparent;
    margin-bottom: 0;

    button {
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--gray-600-color);
      &.active {
        background-color: var(--primary-color);
      }
    }
  }

  .carousel-inner {
    height: 100%;
    padding: 0 2.5rem;
    display: flex;
    align-items: center;
    text-align: center;
  }
}
