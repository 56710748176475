.button {
  border-radius: .65rem;
  padding: .35rem .75rem;
  border: none;
  transition: all 0.2s ease-in-out;
  font-weight: 400;

  white-space: nowrap;

  position: relative;

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover,
  &.active {
    opacity: 0.75;
  }

  i:not(:only-child) {
    margin-right: 0.35rem;
  }

  &.button-accent-primary {
    background-color: var(--primary-color);
    color: #fff;
  }

  &.button-accent-primary-outline {
    background-color: transparent;
    color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
    font-weight: 500;

    &:hover,
    &.active {
      opacity: 1;
      background-color: var(--primary-color);
      color: #fff;
    }
  }

  &.button-accent-transparent {
    background-color: transparent;
    color: var(--background-text-color);

    &:hover {
      opacity: 0.5;
    }
    &.active {
      box-shadow: inset 0px 0px 5px #c1c1c1;
    }
  }

  &.button-accent-light {
    background-color: transparent;
    color: inherit;

    i {
      color: var(--primary-color);
    }

    &:hover,
    &.active {
      box-shadow: 0 3px 2px -2px var(--primary-color);
    }
  }

  &.button-accent-secondary {
    background-color: transparent;
    color: var(--primary-color);

    box-shadow: 0 0 0 1px var(--primary-color);
  }

  &.button-accent-yellow {
    background-color: var(--yellow-color);
    color: white;
  }

  &.button-accent-red {
    background-color: var(--red-color);
    color: white;
  }

  &.button-accent-light-red {
    background-color: transparent;
    color: var(--red-color);
  }

  &.button-accent-green {
    background-color: var(--green-color);
    color: white;
  }

  &.button-accent-light-green {
    background-color: transparent;
    color: var(--green-color);
  }

  &.button-accent-link {
    background-color: transparent;
    color: var(--primary-color);

    &:hover {
      text-decoration: underline;
    }
  }

  &.button-accent-light-outline {
    background-color: transparent;
    color: var(--background-text-color);
    box-shadow: 0 0 0 1px var(--gray-500-color);
    font-weight: 300;
  }

  &.btn-xl {
    padding: 1rem 2rem;
  }

  &.btn-lg {
    padding: 0.5rem 1rem;
  }

  &.btn-xs {
    font-size: .85rem;
    padding: 0.25rem .5rem;
  }

  &.icon-outside {
    margin-left: 2rem;

    > i {
      position: absolute;
      left: -1.35rem;
    }
  }
}

html:not([dir="rtl"]) {
  :global(.btn-group) {
    .button {
      &:global(.dropdown-toggle-split) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:global(.dropdown-toggle-split)) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

html[dir="rtl"] {
  :global(.btn-group) {
    .button {
      &:global(.dropdown-toggle-split) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:not(:global(.dropdown-toggle-split)) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
