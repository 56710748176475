.form-switch .form-check-input:checked {
  background-color: var(--primary-color);
}

.tabs-custom {
  &.tabs-accent-light {
    border: none;
    box-shadow: 0 3px 2px -2px var(--gray-300-color);

    .nav-item {
      padding: 0;

      .nav-link {
        color: var(--primary-color);
        opacity: 0.5;
        border: none;
        border-radius: 0;
        margin: 0;

        &.active,
        &:hover {
          opacity: 1;
          background-color: transparent;
          box-shadow: 0 3px 2px -2px var(--primary-color);
        }
      }
    }
  }
}
