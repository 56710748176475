.page-header {
  color: var(--background-text-color);
}

hr[data-text] {
  opacity: 1;
  &:before {
    content: attr(data-text);
    text-transform: uppercase;
    color: inherit;
    background-color: var(--background-primary-color);
    position: absolute;
    padding: 0 15px;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 100%;
  }
}

body {
  font-size: 1rem;
}
