.multiselectWrapper {
  position: relative;
  width: 100%;

  :global(.rw-state-disabled) {
    opacity: 0.5;
  }

  :global(.rw-widget-input) {
    background-color: transparent;
    //color: var(--background-text-color);
    border: none;
    //border-radius: 0;
    //box-shadow: 0 3px 2px -2px var(--primary-color);
    transition: box-shadow 0.15s ease-in-out;
    border-radius: 0.75rem;
    box-shadow: inset 0 0 2px 0 var(--gray-500-color);

    min-height: 2.15rem;
  }

  :global(.rw-multiselect-input) {
    &::placeholder {
      color: var(--background-text-color);
    }
  }

  :global(.rw-multiselect-tag) {
    border-radius: 0.75rem;
    background-color: var(--highlight-secondary-color);
    color: var(--background-text-color);
    border: none;
    padding: 0 0.5rem;
  }

  :global(.rw-popup-container) {
    left: 0;
    right: 0;
    padding: 0;
  }

  :global(.rw-list),
  :global(.rw-popup) {
    border-radius: .75rem;
    border: none;
    background-color: var(--background-primary-color);
  }

  :global(.rw-list-option) {
    position: relative;
    border: none;
    color: var(--background-text-color);
    padding-left: 1.85rem;

    &:global(.rw-state-selected) {
      background-color: transparent;

      &:before {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        font-size: 0.9rem;
        color: var(--primary-color);
        position: absolute;
        left: 0.5rem;
      }
    }

    &:hover {
      background-color: var(--highlight-color);
    }
  }

  .caretIconWrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
