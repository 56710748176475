.popover {
  border-radius: 1rem;
  padding: 0.75rem;
  font-size: 1rem;

  background-color: var(--background-primary-color);

  p {
    margin-bottom: 0.5rem;
  }
}
