.Toastify__toast {
  font-family: "Montserrat", serif;
  border-radius: 1rem;
}

.Toastify__toast-container {
  width: auto !important;
  min-width: 340px;
  max-width: 550px;
}
